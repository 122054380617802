/** @jsx jsx */
import React from "react" // eslint-disable-line no-unused-vars
import { Link } from "gatsby"
import { jsx, Container, Grid, Box } from "theme-ui"

function MobileNav({ toggle }) {
  return (
    <div
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        height: `calc(100vh - env(safe-area-inset-bottom))`,
        width: "100%",
        backgroundColor: "background",
      }}
    >
      <Box
        sx={{
          height: `calc(100vh - 200px - env(safe-area-inset-bottom))`,
          borderColor: "primary",
          borderStyle: "solid",
          borderTopWidth: 1,
          borderBottomWidth: 1,
          borderRightWidth: 0,
          borderLeftWidth: 0,
          mt: "100px",
          p: 7,
          // mx: "50px",
          fontSize: 6,
          a: {
            color: "black",
            textDecoration: "none",
            ":visited": {
              color: "black",
            },
            ":hover": {
              color: "secondary",
            },
            "&.active": {
              textDecoration: "underline",
            },
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            my: 6,
          }}
        >
          <Link
            to="/presenters"
            activeClassName="active"
            sx={
              {
                // pr: [1, 2],
              }
            }
            onClick={() => toggle()}
          >
            Presenters
          </Link>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            my: 6,
          }}
        >
          <Link
            to="/show"
            activeClassName="active"
            sx={
              {
                // px: [1, 2],
              }
            }
            onClick={() => toggle()}
          >
            Show
          </Link>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            my: 6,
          }}
        >
          <Link
            to="/behind-the-screen"
            activeClassName="active"
            sx={
              {
                // px: [1, 2],
              }
            }
            onClick={() => toggle()}
          >
            Behind the screen
          </Link>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            my: 6,
          }}
        >
          <Link
            to="/share"
            activeClassName="active"
            sx={
              {
                // pl: [1, 2],
              }
            }
            onClick={() => toggle()}
          >
            Share
          </Link>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            my: 6,
          }}
        >
          <a
            href="https://www.gmdlcc.com/archive/"
            activeClassName="active"
            sx={
              {
                // pl: [1, 2],
              }
            }
            onClick={() => toggle()}
          >
            Archive
          </a>
        </Box>
      </Box>
    </div>
  )
}

export default MobileNav
