/** @jsx jsx */
import React from "react" // eslint-disable-line no-unused-vars
import { Link } from "gatsby"
import { jsx, Container, Grid, Flex, Box } from "theme-ui"
import { useBreakpointIndex } from "@theme-ui/match-media"
import { motion, useCycle } from "framer-motion"
import { MenuToggle } from "./MenuToggle"
import MobileNav from "./MobileNav"
import GridList from "./GridList"
import CategoriesGrid from "./CategoriesGrid"
import SidePointer from "../../images/svgs/sidepointer.svg"

function Header({ props }) {
  const index = useBreakpointIndex()
  const duration = 0
  const springStiffness = 130
  const springDamping = 11
  const variantsFade = {
    initial: {
      y: "-180vh",
    },
    enter: {
      y: 0,
      transition: {
        type: "spring",
        stiffness: springStiffness,
        delay: duration,
        when: "beforeChildren",
      },
    },
    exit: {
      y: "-180vh",
      transition: {
        type: "spring",
        stiffness: springStiffness,
        delay: duration,
        when: "beforeChildren",
      },
    },
  }
  const locationPathArray = props.location.pathname.split("/").filter((el) => {
    return el != ""
  })
  const presentersOnly =
    locationPathArray[0] === "presenters" && locationPathArray.length === 1
  const gridListOn =
    locationPathArray[0] === "presenters" ||
    props.location.pathname === "/presenters-list"
  const catListOn =
    (locationPathArray[0] === "presenters" && locationPathArray.length > 1) ||
    props.location.pathname === "/presenters-list"

  const [isOpen, toggleOpen] = useCycle(false, true)
  return (
    <div>
      <motion.nav
        initial={false}
        animate={isOpen ? "enter" : "exit"}
        sx={{
          zIndex: 1000,
          position: "relative",
          display: ["block", "none"],
        }}
      >
        <motion.div variants={variantsFade}>
          <MobileNav toggle={() => toggleOpen()} />
        </motion.div>
      </motion.nav>
      <header
        sx={{
          fontSize: [6, 5],
          a: {
            color: "black",
            textDecoration: "none",
            ":visited": {
              color: "black",
            },
            ":hover": {
              // color: "secondary",
            },
            "&.active": {
              textDecoration: "underline",
            },
          },
        }}
      >
        <Container
          sx={{
            alignItems: "top",
            p: [3, 4],
          }}
        >
          <Grid columns={["1fr 1fr", "1fr 3fr"]}>
            <Box
              sx={{
                zIndex: 2000,
                position: "relative",
                a: {
                  color: "primary",
                  textDecoration: "none",
                  ":visited": {
                    color: "primary",
                    textDecoration: "none",
                  },
                  ":hover": {
                    color: "secondary",
                    textDecoration: "none",
                  },
                  // textDecoration: "underline",
                },
              }}
            >
              <Link
                to="/"
                sx={{
                  pr: [1, 2],
                }}
              >
                <span sx={{ textDecoration: "underline" }}>gatewa</span>y/
                <span sx={{ textDecoration: "underline" }}>gmd</span>
              </Link>
            </Box>
            <Box
              sx={{
                display: ["none", "flex"],
                justifyContent: "flex-end",
              }}
            >
              <Link
                to="/presenters"
                activeClassName="active"
                sx={{
                  pr: [1, 2],
                }}
              >
                Presenters
              </Link>
              <Link
                to="/show"
                activeClassName="active"
                sx={{
                  px: [1, 2],
                }}
              >
                Show
              </Link>
              <Link
                to="/behind-the-screen"
                activeClassName="active"
                sx={{
                  px: [1, 2],
                }}
              >
                Behind the screen
              </Link>
              <Link
                to="/share"
                activeClassName="active"
                sx={{
                  px: [1, 2],
                }}
              >
                Share
              </Link>
              <a
                href="https://www.gmdlcc.com/archive/"
                activeClassName="active"
                sx={{
                  pl: [1, 2],
                }}
              >
                Archive
              </a>
            </Box>
            <Box
              sx={{
                display: ["flex", "none"],
                justifyContent: "flex-end",
                zIndex: 2000,
                position: "relative",
              }}
            >
              <motion.nav initial={false} animate={isOpen ? "open" : "closed"}>
                <MenuToggle toggle={() => toggleOpen()} />
              </motion.nav>
            </Box>
          </Grid>
        </Container>

        {presentersOnly && (
          <Container px={[3, 4]}>
            <Flex
              sx={{
                justifyContent: "flex-start",
                alignItems: "top",
                mt: [6, 7],
                // mb: [5, 5],
              }}
            >
              <Box
                sx={{
                  flexGrow: 0,
                  flexShrink: 0,
                  flexBasis: ["40px", "40px", "80px"],
                  maxHeight: ["40px", "40px", "80px"],
                  mr: [3, 6],
                }}
              >
                <img src={SidePointer} alt="pointer" />
              </Box>
              <Box
                sx={{
                  variant: "type.quote",
                  a: {
                    color: "primary",
                    textDecoration: "underline",
                    ":visited": {
                      textDecoration: "none",
                      color: "primary",
                    },
                    ":hover": {
                      textDecoration: "none",
                      color: "secondary",
                    },
                  },
                }}
              >
                From one world to another, from us to you, and from{" "}
                <Link to="/share">hyperlink</Link> to{" "}
                <Link to="/behind-the-screen">hyperlink</Link>, a single link
                becomes the <Link to="/">gateway</Link> that opens a door to new
                perspectives, possibilities and new work from our BA Graphic and
                Media Design graduates.
              </Box>
            </Flex>
          </Container>
        )}
        {catListOn && (
          <Container px={[3, 4]}>
            <CategoriesGrid />
          </Container>
        )}
        {gridListOn && (
          <Container px={[3, 4]}>
            <GridList />
          </Container>
        )}
      </header>
    </div>
  )
}

export default Header
