/** @jsx jsx */
import React from "react"
import { Link } from "gatsby"
import { jsx, Flex, Box } from "theme-ui"
import SidePointer from "../../images/svgs/sidepointer.svg"

function GridList() {
  return (
    <>
      <Flex
        sx={{
          justifyContent: "flex-start",
          alignItems: "top",
          mt: [6, 7],
          mb: [5, 5],
        }}
      >
        <Box
          sx={{
            flexGrow: 0,
            flexShrink: 0,
            flexBasis: ["40px", "40px", "80px"],
            maxHeight: ["40px", "40px", "80px"],
            mr: [3, 6],
          }}
        >
          <img src={SidePointer} sx={{}} alt="pointer" />
        </Box>
        <Box
          sx={{
            variant: "type.heading",
            // mb: 5,
            a: {
              color: "#000",
              textDecoration: "none",
              ":hover": {
                color: "#000",
                textDecoration: "underline",
              },
              ":visited": {
                color: "#000",
              },
              "&.active": {
                textDecoration: "underline",
                // color: "secondary",
              },
            },
          }}
        >
          <span>
            <Link to={`/presenters`} activeClassName="active">
              Images
            </Link>
            <span sx={{ px: 1 }}> / </span>
          </span>
          <span>
            <Link to={`/presenters-list`} activeClassName="active">
              Names
            </Link>
          </span>
        </Box>
      </Flex>
    </>
  )
}

export default GridList
