import React from "react"
import { motion, AnimatePresence } from "framer-motion"

const Transition = ({ children, location }) => {
  const duration = 0
  const springStiffness = 130
  const springDamping = 11

  const variantsFade = {
    initial: {
      opacity: 0,
    },
    enter: {
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: springStiffness,
        delay: duration,
        when: "beforeChildren",
      },
    },
    exit: {
      opacity: 0,
      transition: { duration: duration },
    },
  }
  const transitionRightToLeft = {
    initial: {
      opacity: 0,
      x: "100vw",
    },
    enter: {
      opacity: 1,
      x: 0,
      transition: {
        type: "spring",
        stiffness: springStiffness,
        damping: springDamping,
        delay: duration,
        when: "beforeChildren",
      },
    },
    exit: {
      opacity: 0,
      x: "-100vw",
      transition: { duration: duration },
    },
  }
  const transitionTopDown = {
    initial: {
      opacity: 0,
      y: "-100vh",
    },
    enter: {
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        stiffness: springStiffness,
        damping: springDamping / 2,
        delay: duration,
        when: "beforeChildren",
      },
    },
    exit: {
      opacity: 0,
      y: "100vh",
      transition: { duration: duration },
    },
  }
  const transitionBottomUp = {
    initial: {
      opacity: 0,
      y: "100vh",
    },
    enter: {
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        stiffness: springStiffness,
        damping: springDamping,
        delay: duration,
        when: "beforeChildren",
      },
    },
    exit: {
      opacity: 0,
      y: "-100vh",
      transition: { duration: duration },
    },
  }

  const locationPathLength = location.pathname.split("/").length
  const locationPathArray = location.pathname.split("/")

  let variant
  if (location.pathname === "/") {
    variant = transitionTopDown
  } else if (
    locationPathArray[0] === "presenters" &&
    locationPathArray.length === 2
  ) {
    variant = transitionBottomUp
  } else {
    variant = transitionBottomUp
  }

  return (
    <AnimatePresence>
      <motion.div
        key={location?.pathname}
        variants={variant}
        initial="initial"
        animate="enter"
        exit="exit"
      >
        {children}
      </motion.div>
    </AnimatePresence>
  )
}

export default Transition
