/** @jsx jsx */
import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { Link } from "gatsby"
import { jsx, Grid, Flex, Box } from "theme-ui"
import PlaceholderImage from "../images/trans.png"
import SEO from "../components/navigation/seo"
import SidePointer from "../images/svgs/sidepointer.svg"

export const placeholderImageFluid = {
  aspectRatio: 4 / 2.8,
  src: PlaceholderImage,
  srcSet: `${PlaceholderImage} 1w`,
  sizes: "(max-width: 1px) 100vw, 1px",
}

export const query = graphql`
  query BenhindQuery {
    students: allWpPost(
      filter: { slug: { ne: "peter-cannings-award" } }
      sort: { order: ASC, fields: portfolio___displayName }
    ) {
      edges {
        node {
          # title
          slug
          id
          uri
          portfolio {
            displayName
            behindTheScreen {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 380) {
                    # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
    categories: allWpCategory(filter: { slug: { ne: "uncategorised" } }) {
      edges {
        node {
          slug
          name
        }
      }
    }
  }
`

const BehindTheScreen = ({ data }) => {
  const { students } = data
  return (
    <>
      <SEO
        title="behind the screen"
        description={
          "The screen is the portal into our work. We celebrate process, acknowledging that the conditions were far from perfect. Our homes became creative spaces, and our roommates, friends and family became our actors, technicians, and workshop participants. It has been a challenge we all mastered from our homes around the world."
        }
      />
      <Flex
        sx={{
          justifyContent: "flex-start",
          alignItems: "top",
          mt: [6, 7],
          mb: [5, 5],
        }}
      >
        <Box
          sx={{
            flexGrow: 0,
            flexShrink: 0,
            flexBasis: ["40px", "40px", "80px"],
            maxHeight: ["40px", "40px", "80px"],
            mr: [3, 6],
          }}
        >
          <img src={SidePointer} alt="pointer" />
        </Box>
        <Box
          sx={{
            variant: "type.quote",
            a: {
              color: "primary",
              textDecoration: "underline",
              ":visited": {
                textDecoration: "none",
                color: "primary",
              },
              ":hover": {
                textDecoration: "none",
                color: "secondary",
              },
            },
          }}
        >
          The screen is the portal into our work. We celebrate process,
          acknowledging that the conditions were far from perfect. Our homes
          became creative spaces, and our roommates, friends and family became
          our actors, technicians, and workshop participants. It has been a
          challenge we all mastered from our homes around the world.
        </Box>
      </Flex>

      <Grid columns={[2, 3, 4]} gap={2}>
        {students.edges
          .sort(() => Math.random() - 0.5)
          .map(({ node }, index) => (
            <Box
              key={`student-${index}`}
              sx={{
                ":hover": {
                  backgroundColor: "primary",
                },
              }}
            >
              <Link to={`/${node.slug}`}>
                {node.portfolio.behindTheScreen?.localFile?.childImageSharp
                  ?.fluid ? (
                  <Img
                    fluid={{
                      ...node.portfolio.behindTheScreen.localFile
                        .childImageSharp.fluid,
                      ...{ aspectRatio: 4 / 2.8 },
                    }}
                    alt={node.portfolio.displayName}
                  />
                ) : (
                  <Img
                    fluid={{
                      ...placeholderImageFluid,
                      ...{ aspectRatio: 4 / 2.8 },
                    }}
                    sx={
                      {
                        // borderBottomStyle: "solid",
                        // borderWidth: 1,
                        // borderColor: "#ee0000",
                        // backgroundColor: "#0000ee",
                      }
                    }
                    alt={node.portfolio.displayName}
                  />
                )}
              </Link>
            </Box>
          ))}
      </Grid>
    </>
  )
}

export default BehindTheScreen
