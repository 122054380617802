/** @jsx jsx */
import React from "react" // eslint-disable-line no-unused-vars
import { Link } from "gatsby"
import { jsx, Grid, Flex, Box } from "theme-ui"
import SEO from "../components/navigation/seo"
import SidePointer from "../images/svgs/sidepointer.svg"
import BlueClap from "../images/svgs/clap.svg"
import Clap from "../images/redsvgs/clap.svg"
import ReactPlayer from "react-player/lazy"
import Camera from "../images/svgs/camera.svg"
import Mic from "../images/svgs/mic.svg"

function About() {
  return (
    <>
      <SEO
        title="show"
        description={
          "BA Graphic Media Design degree show 2020 showreel and events"
        }
      />
      <Box
        sx={{
          position: "relative",
          pt: "56.25%" /* Player ratio: 100 / (1280 / 720) */,
        }}
      >
        <ReactPlayer
          url={"https://youtu.be/7Y5bl0nEQuQ"}
          config={{
            youtube: {
              playerVars: { showinfo: 1, controls: true },
              embedOptions: {},
            },
          }}
          playing={false}
          width="100%"
          height="100%"
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
          }}
        />
      </Box>
      <Flex
        sx={{
          justifyContent: "flex-start",
          alignItems: "top",
          mt: [6, 7],
          mb: [5, 5],
        }}
      >
        <Box
          sx={{
            flexGrow: 0,
            flexShrink: 0,
            flexBasis: ["40px", "40px", "80px"],
            maxHeight: ["40px", "40px", "80px"],
            mr: [3, 6],
          }}
        >
          <img src={SidePointer} alt="pointer" />
        </Box>
        <Box
          sx={{
            variant: "type.quote",
            a: {
              color: "primary",
              textDecoration: "underline",
              ":visited": {
                textDecoration: "none",
                color: "primary",
              },
              ":hover": {
                textDecoration: "none",
                color: "secondary",
              },
            },
          }}
        >
          <p>
            View our <a href="https://gmdlcc.com/archive">Archive</a>.
          </p>
        </Box>
      </Flex>
      <Grid columns={["1fr", "1fr 1fr 1fr"]}>
        <Flex
          sx={{
            justifyContent: "flex-start",
            alignItems: "top",
            mt: [6, 7],
            mb: [5, 5],
          }}
        >
          <Box
            sx={{
              flexGrow: 0,
              flexShrink: 0,
              flexBasis: ["40px", "40px", "80px"],
              maxHeight: ["40px", "40px", "80px"],
              mr: [3, 6],
            }}
          >
            <img src={BlueClap} alt="pointer" />
          </Box>
          <Box
            sx={{
              // variant: "type.quote",
              a: {
                color: "primary",
                textDecoration: "underline",
                ":visited": {
                  textDecoration: "none",
                  color: "primary",
                },
                ":hover": {
                  textDecoration: "none",
                  color: "secondary",
                },
              },
            }}
          >
            <h3 sx={{ pb: [5, 6] }}>Image material</h3>
            <Box>GMD Class 2020</Box>
          </Box>
        </Flex>
        <Flex
          sx={{
            justifyContent: "flex-start",
            alignItems: "top",
            mt: [6, 7],
            mb: [5, 5],
          }}
        >
          <Box
            sx={{
              flexGrow: 0,
              flexShrink: 0,
              flexBasis: ["40px", "40px", "80px"],
              maxHeight: ["40px", "40px", "80px"],
              maxHeight: ["40px", "80px"],
              mr: [3, 6],
            }}
          >
            <img
              src={Camera}
              alt="pointer"
              sx={{ height: "100%", width: "100%" }}
            />
          </Box>
          <Box
            sx={{
              // variant: "type.quote",
              a: {
                color: "primary",
                textDecoration: "underline",
                ":visited": {
                  textDecoration: "none",
                  color: "primary",
                },
                ":hover": {
                  textDecoration: "none",
                  color: "secondary",
                },
              },
            }}
          >
            <h3 sx={{ pb: [5, 6] }}>Motion Graphics</h3>

            <Box>
              <Link to="/monika-adamczyk">Monika Adamczyk</Link>
            </Box>
            <Box>
              <Link to="/veronica-jones">Veronica Jones</Link>
            </Box>
            <Box>
              <Link to="/ester-mejibovski">Ester Mejibovski</Link>
            </Box>
          </Box>
        </Flex>
        <Flex
          sx={{
            justifyContent: "flex-start",
            alignItems: "top",
            mt: [6, 7],
            mb: [5, 5],
          }}
        >
          <Box
            sx={{
              flexGrow: 0,
              flexShrink: 0,
              flexBasis: ["40px", "40px", "80px"],
              maxHeight: ["40px", "40px", "80px"],
              mr: [3, 6],
            }}
          >
            <img
              src={Mic}
              alt="pointer"
              sx={{ height: "100%", width: "100%" }}
            />
          </Box>
          <Box
            sx={{
              // variant: "type.quote",
              a: {
                color: "primary",
                textDecoration: "underline",
                ":visited": {
                  textDecoration: "none",
                  color: "primary",
                },
                ":hover": {
                  textDecoration: "none",
                  color: "secondary",
                },
              },
            }}
          >
            <h3 sx={{ pb: [5, 6] }}>Sound</h3>
            <Box>
              <a
                href="http://cargocollective.com/ronnieism"
                target="_blank"
                rel="noreferrer"
                sx={{
                  color: "black",
                  textDecoration: "none",
                }}
              >
                Ronnie Deelen
              </a>
            </Box>
          </Box>
        </Flex>
      </Grid>
    </>
  )
}

export default About
