export default {
  styles: {
    root: {
      fontFamily: "body",
      lineHeight: "body",
      fontWeight: "body",
      fontSize: [3, 4],
      // cursor: `url("data:image/svg+xml,%3Csvg id='Layer_7' data-name='Layer 7' xmlns='http://www.w3.org/2000/svg' width='50px' height='50px' viewBox='0 0 50 50' %3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:none;stroke:%23e00;stroke-linecap:round;stroke-linejoin:round;stroke-width:1px;%7D%3C/style%3E%3C/defs%3E%3Cpath class='cls-1' d='M19.62,10.45c-1.48-1.26-3.23-2.11-4.73-.42a2.79,2.79,0,0,0-.66,1.24,2.87,2.87,0,0,0,.4,2.09l-.46-.47c-1-1-2.73-1.88-4.05-.64-1.48,1.39-.46,3.43.65,4.55l3,3-.65-.64c-1-1-3-1.62-4.14-.25S8.77,22,9.75,23c0,0,2.07,2,3,3l-.82-.83C10.66,23.87,9,23.44,8,24.42l-.13.13c-.91,1-.19,2.8,1.08,4.08L11.32,31l2,2.11.51.5,6.61,6.65a12.46,12.46,0,0,0,6.81,3.33l2.55,2.61a2.34,2.34,0,0,0,3.32.13l.63-.62'/%3E%3Cpath class='cls-1' d='M4.58,3.73,9.73,9.51M11.82.78,13.48,7.3M.78,11l6.36,1.9'/%3E%3Cpath class='cls-1' d='M24.84,33.16l-7.71-7.8c-1.27-1.28-2.9-1.71-3.87-.73l-.13.13c-.9,1-.19,2.8,1.08,4.08l2.31,2.33,2,2.11.59.57,6.57,6.66a12.25,12.25,0,0,0,6.68,3.25l2.53,2.61a2.33,2.33,0,0,0,3.31.13L46.67,38a2.4,2.4,0,0,0-.14-3.35l-2.61-2.7c.2-3.09.32-6.36-.86-9.29a11.74,11.74,0,0,0-2.74-4c-1-1-1.36-1.37-2.38-2.35a10.69,10.69,0,0,0-3.17-2.48l-.06,0a2.46,2.46,0,0,0-2.58.43,2.32,2.32,0,0,0-.53,2,5.16,5.16,0,0,0,1.23,2.33c1.79,2.19,3.41,3.28,5.33,5.36-3.54-3.59-7.21-7-10.8-10.57-.34-.34-.68-.69-1-1-1.47-1.46-4.14-4.5-6.27-2.19a2.39,2.39,0,0,0-.6,1.11c-.29,1.32.95,2.87,1.31,3.28a.45.45,0,0,0,.07.09c.61.69,4.16,4.47,9.46,9.73l-11-11.15c-.93-1.13-2.61-2-3.92-.73-1.48,1.39-.46,3.43.64,4.54l3.82,3.86,7.75,7.85m-.9-.91-8.35-8.45c-1-1-2.95-1.62-4.12-.25S14,22.23,15,23.21l3.23,3.27,6.63,6.7'/%3E%3C/svg%3E"), pointer`,
      a: {
        color: "primary",
        // textDecoration: "none",
        ":visited": {
          color: "visited",
        },
        ":hover": {
          color: "secondary",
          textDecoration: "underline",
        },
      },
      p: {
        mt: 0,
      },
      h1: {
        variant: "text.heading",
        fontSize: [6, 8, 8],
        // textTransform: "uppercase",
        my: [1, 2],
      },
      h2: {
        variant: "text.heading",
        fontSize: [6, 8, 8],
        // textTransform: "uppercase",

        fontSize: [3, 4],
      },
      h3: {
        variant: "text.heading",
        // dangerously set html can be an H3 tag
        // so reset styles to be similar to normal text size below

        fontSize: [3, 4],
        m: 0,
        // textTransform: "uppercase",
        // my: [1, 2],
      },
      h4: {
        variant: "type.heading",
        fontSize: [1, 2, 2],
        // textTransform: "uppercase",
        my: [1, 2],
      },
      h5: {
        fontSize: [1, 2, 2],
        // textTransform: "uppercase",
        my: [0],
      },
      strong: {
        // dangerously set html can be an <strong> tag
        // so reset styles to be similar to normal text size below
        fontWeight: "bold",
      },
    },
    header: {},
    footer: {
      a: {
        color: "text",
      },
    },
    h4: {
      variant: "text.heading",
      fontSize: [1, 2],
    },
    h5: {
      variant: "text.heading",
      fontSize: [0, 1],
    },
    h6: {
      variant: "text.heading",
      fontSize: 0,
    },
    pre: {
      fontFamily: "monospace",
      overflowX: "auto",
      code: {
        color: "inherit",
      },
    },
    code: {
      fontFamily: "monospace",
      fontSize: "inherit",
    },
    table: {
      width: "100%",
      borderCollapse: "separate",
      borderSpacing: 0,
    },
    th: {
      textAlign: "left",
      borderBottomStyle: "solid",
    },
    td: {
      textAlign: "left",
      borderBottomStyle: "solid",
    },
    list: {
      listStyle: "none",
      p: 0,
      m: 0,
    },
  },
}
