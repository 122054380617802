/** @jsx jsx */
import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { jsx, Flex, Box } from "theme-ui"
import SidePointer from "../../images/svgs/sidepointer.svg"

function CategoriesGrid() {
  const data = useStaticQuery(graphql`
    query categoriesQuery {
      allWpCategory(filter: { slug: { ne: "uncategorised" } }) {
        edges {
          node {
            slug
            name
          }
        }
      }
    }
  `)
  const categories = data.allWpCategory

  return (
    <>
      <Flex
        sx={{ justifyContent: "flex-start", alignItems: "top", mt: [6, 7] }}
      >
        <Box
          sx={{
            flexGrow: 0,
            flexShrink: 0,
            flexBasis: ["40px", "40px", "80px"],
            maxHeight: ["40px", "40px", "80px"],
            mr: [3, 6],
          }}
        >
          <img src={SidePointer} alt="pointer" />
        </Box>
        <Box
          sx={{
            variant: "type.heading",
            // mb: 5,
            a: {
              color: "#000",
              textDecoration: "none",
              ":hover": {
                color: "#000",
                textDecoration: "underline",
              },
              ":visited": {
                color: "#000",
              },
              "&.active": {
                textDecoration: "underline",
                // color: "secondary",
              },
            },
          }}
        >
          <span>
            <Link to={`/presenters-list`} activeClassName="active">
              All
            </Link>
            <span sx={{ px: 1 }}> / </span>
          </span>
          {categories.edges.map(({ node }, index) => {
            return (
              <span key={index}>
                <Link to={`/presenters/${node.slug}`} activeClassName="active">
                  {node.name}
                </Link>
                {index < categories.edges.length - 1 ? (
                  <span sx={{ px: 1 }}> / </span>
                ) : (
                  " "
                )}
              </span>
            )
          })}
        </Box>
      </Flex>
    </>
  )
}

export default CategoriesGrid
