/** @jsx jsx */
import React from "react" // eslint-disable-line no-unused-vars
import { Link } from "gatsby"
import { jsx, Container, Flex, Grid, Box, useThemeUI } from "theme-ui"
import { useBreakpointIndex } from "@theme-ui/match-media"
import SidePointer from "../../images/svgs/hand.svg"

const Footer = () => {
  const { theme } = useThemeUI()
  return (
    <Box
      sx={{
        mt: 5,
        backgroundColor: "primary",
        borderTopColor: "primary",
        borderTopWidth: 1,
        borderTopStyle: "solid",
        color: "white",
        a: {
          color: "white",

          textDecoration: "none",
          ":visited": {
            // textDecoration: "none",
            color: "white",
          },
          ":hover": {
            // textDecoration: "none",
            color: "secondary",
          },
          "&.active": {
            textDecoration: "underline",
            // color: "secondary",
          },
        },
        position: "relative",
        zIndex: 10,
      }}
    >
      <Container sx={{ px: [3, 4], py: 6 }}>
        <Box sx={{ width: "100px" }}>
          <svg
            viewBox="0 0 595.28 841.89"
            stroke="white"
            fill="none"
            strokeWidth="10px"
            alt="hand"
          >
            <g>
              <g>
                <path
                  d="M252.88,577.05l0.42,42.17c0,15.12,11.36,27.42,25.31,27.42h134.63c13.96,0,25.31-12.31,25.31-27.42
			l-0.42-40.76c16.49-22.1,27.7-51.82,27.7-80.16l-0.13-104.84l0.13-8.01l-0.39-32.84l0.13-36.79c0-20.2-8.22-39.78-23.6-40.77
			h-2.05c-13.89,0-22.99,13.79-24.77,31.01v-0.65c0-13.51,0-27.84,0-27.98c0-19.07-12.45-43.14-33.92-37.91
			c-12.16,2.96-18.76,15.86-21.33,27.12c-0.2-21.32,5.99-55.75-17.11-67.99c-8.86-4.7-20.18-4.37-28.75,0.83
			c-21.37,12.95-17.77,49.96-17.7,71.25c-0.03-8.68-0.19-17.56-2.47-25.99c-1.87-6.88-5.3-13.49-10.68-18.16
			c-4.79-4.15-11.04-6.61-17.38-6.7c-6.34-0.09-12.7,2.21-17.44,6.42c-11.09,9.86-11.85,26.77-12.08,40.52
			c-0.68,40.26,0.53,80.59,0.54,120.86c0,8.16-0.01,16.33-0.01,24.49c0,2.03,0,4.06,0,6.08c0.1,14.85,0.12,29.69,0.12,44.54
			c-0.06,20.35-26.89-4.05-32.52-8.79c-12.21-10.27-24.98-22.56-40.73-27c-17-4.79-34.24,8.51-33.51,26.58
			c0.38,9.36,5.82,17.82,12.32,24.57c14.76,15.33,31.83,28.94,47.72,43.08C211.11,539.85,231.99,558.45,252.88,577.05z"
                />
              </g>
              <line x1="296.35" y1="270.72" x2="296.33" y2="436.78" />
              <line x1="359.91" y1="266.65" x2="359.91" y2="441.69" />
              <line x1="415.17" y1="305.79" x2="415.17" y2="454.28" />
            </g>
          </svg>
        </Box>
        <Grid columns={["1fr", "1fr 3fr"]}>
          <Box>
            <a
              href="https://www.instagram.com/gmdlcc/"
              rel="noreferrer"
              target="_blank"
              sx={{
                variant: "styles.navlink",
                pr: 2,
              }}
            >
              Instagram
            </a>
            <a
              href="https://twitter.com/gmdlcc"
              rel="noreferrer"
              target="_blank"
              sx={{
                variant: "styles.navlink",
                pl: 2,
              }}
            >
              Twitter
            </a>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: ["flex-start", "flex-end"],
            }}
          >
            <Link
              to="/presenters"
              sx={{
                variant: "styles.navlink",
                pr: 2,
              }}
            >
              Presenters
            </Link>
            <Link
              to="/show"
              sx={{
                variant: "styles.navlink",
                px: 2,
              }}
            >
              Show
            </Link>
            <Link
              to="/behind-the-screen"
              sx={{
                variant: "styles.navlink",
                px: 2,
              }}
            >
              Behind the screen
            </Link>
            <Link
              to="/share"
              sx={{
                variant: "styles.navlink",
                px: 2,
              }}
            >
              Share
            </Link>
            <a
              href="https://gmdlcc.com/archive"
              sx={{
                variant: "styles.navlink",
                pl: 2,
              }}
            >
              Archive
            </a>
          </Box>
        </Grid>
      </Container>
    </Box>
  )
}

export default Footer
