import React from "react"
import loadable from "@loadable/component"
/** @jsx jsx */
import { jsx, Box } from "theme-ui"
import Clap from "../images/svgs/clap.svg"

import RedClap from "../images/redsvgs/clap.svg"

import PurpleClap from "../images/purplesvgs/clap.svg"

function HomeInteraction() {
  if (typeof window !== "undefined") {
    const Sketch = loadable(() => import("react-p5"))
    let x = 50
    let y = 50
    let frame = 0
    let clap
    let redclap
    let purpleclap

    let svgs = []
    let svg = 0
    let glyphs = []

    let x0off = 0.0
    let y0off = 10.0
    let x1off = 20.0
    let y1off = 30.0
    let x2off = 40.0
    let y2off = 50.0
    let x3off = 60.0
    let y3off = 70.0
    let zoff = 0.0
    let x0inc = 0.008
    let y0inc = 0.0086
    let x1inc = 0.007
    let y1inc = 0.0077
    let x2inc = 0.012
    let y2inc = 0.013
    let x3inc = 0.0061
    let y3inc = 0.006
    let zinc = 0.044

    const setup = (p5, canvasParentRef) => {
      // use parent to render the canvas in this ref
      // (without that p5 will render the canvas outside of your component)
      p5.createCanvas(p5.windowWidth, p5.windowHeight).parent(canvasParentRef)
      x = p5.width - p5.width / 4
      y = p5.height / 4

      clap = p5.loadImage(Clap)
      redclap = p5.loadImage(RedClap)
      purpleclap = p5.loadImage(PurpleClap)

      svgs = [clap, redclap, purpleclap]

      svg = Math.floor(p5.random(svgs.length))
    }

    const draw = (p5) => {
      p5.background(255)
      p5.fill(200)
      p5.noStroke()

      x0off = x0off + x0inc
      let nx0 = p5.noise(x0off) * p5.width
      y0off = y0off + y0inc
      let ny0 = p5.noise(y0off) * p5.height

      x1off = x1off + x1inc
      let nx1 = p5.noise(x1off) * p5.width
      y1off = y1off + y1inc
      let ny1 = p5.noise(y1off) * p5.height

      x2off = x2off + x2inc
      let nx2 = p5.noise(x2off) * p5.width
      y2off = y2off + y2inc
      let ny2 = p5.noise(y2off) * p5.height

      x3off = x3off + x3inc
      let nx3 = p5.noise(x3off) * p5.width
      y3off = y3off + y3inc
      let ny3 = p5.noise(y3off) * p5.height

      zoff = zoff + zinc
      let nz = p5.noise(zoff) * 100

      p5.imageMode(p5.CENTER)
      if (svgs !== "undefined" && svg != "undefined") {
        let img = svgs[0]
        if (img) {
          p5.image(img, nx0, ny0)
          p5.image(img, nx1, ny1)
          p5.image(img, nx2, ny2)
          p5.image(img, nx3, ny3)

          glyphs.map((glyph) => {
            p5.image(glyph.svg, glyph.x, glyph.y)
            glyph.y += glyph.speed
            glyph.speed += 0.14
          })
          let xPos = p5.mouseX > 0 && p5.mouseY > 0 ? p5.mouseX : x
          let yPos = p5.mouseX > 0 && p5.mouseY > 0 ? p5.mouseY : y
          p5.image(svgs[svg], xPos, yPos)
        }
        if (frame % 12 === 0) {
          svg = Math.floor(p5.random(svgs.length))
        }
      }

      let randomDrop = p5.random(100)
      if (randomDrop > 98) {
        glyphs.push({
          x: nx0,
          y: ny0,
          svg: svgs[1],
          speed: 1,
        })
      }
      if (randomDrop > 99) {
        glyphs.push({
          x: nx1,
          y: ny1,
          svg: svgs[svg],
          speed: 1,
        })
      }
      if (randomDrop > 98) {
        glyphs.push({
          x: nx2,
          y: ny2,
          svg: svgs[svg],
          speed: 1,
        })
      }
      if (randomDrop > 98) {
        glyphs.push({
          x: nx3,
          y: ny3,
          svg: svgs[svg],
          speed: 1,
        })
      }

      frame++
    }

    const windowResized = (p5) => {
      p5.resizeCanvas(p5.windowWidth, p5.windowHeight)
    }

    function mousePressed(p5) {
      glyphs.push({
        x: p5.mouseX,
        y: p5.mouseY,
        svg: svgs[svg],
        speed: 1,
      })
    }

    return (
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: -10,
          cursor: "none",
        }}
      >
        <Sketch
          // preload={preload}
          setup={setup}
          draw={draw}
          windowResized={windowResized}
          mousePressed={mousePressed}
        />
      </Box>
    )
  } else {
    return null
  }
}

export default HomeInteraction
