/** @jsx jsx */
import React from "react" // eslint-disable-line no-unused-vars
import { Link } from "gatsby"
import { jsx, Grid, Flex, Box } from "theme-ui"
import SEO from "../components/navigation/seo"
import SidePointer from "../images/svgs/sidepointer.svg"
import Clap from "../images/svgs/clap.svg"

import ReactPlayer from "react-player/lazy"

function Archive() {
  return (
    <>
      <SEO
        title="archive"
        description={
          "Archive of the Graphic Media Deign degree show, view all the previous years"
        }
      />
      <Flex
        sx={{
          justifyContent: "flex-start",
          alignItems: "top",
          mt: [6, 7],
          mb: [5, 5],
        }}
      >
        <Box
          sx={{
            flexGrow: 0,
            flexShrink: 0,
            flexBasis: ["40px", "40px", "80px"],
            maxHeight: ["40px", "40px", "80px"],
            mr: [3, 6],
          }}
        >
          <img src={SidePointer} alt="pointer" />
        </Box>
        <Box
          sx={{
            variant: "type.quote",
            a: {
              color: "primary",
              textDecoration: "underline",
              ":visited": {
                textDecoration: "none",
                color: "primary",
              },
              ":hover": {
                textDecoration: "none",
                color: "secondary",
              },
            },
          }}
        >
          <p>Graphic Media Design degree show archive:</p>

          <p>
            <a href="https://gmdlcc.com/">2020</a>
          </p>
          <p>
            <a href="https://2019.gmdlcc.com/">2019</a>
          </p>
          <p>
            <a href="https://2018.gmdlcc.com/">2018</a>
          </p>
          <p>
            <a href="https://2017.gmdlcc.com/">2017</a>
          </p>
          <p>
            <a href="https://2016.gmdlcc.com/">2016</a>
          </p>
        </Box>
      </Flex>
    </>
  )
}

export default Archive
