export default {
  type: {
    heading: {
      fontSize: [5, 5],
    },
    list: {
      fontSize: [3, 5],
    },
    filter: {
      fontSize: [3, 4],
    },
    sectionHeader: {
      fontSize: [6, 7],
    },
    quote: {
      fontSize: [5, 6],
      mb: [4, 5],
    },
    description: {
      mb: [4, 5],
    },
  },
}
