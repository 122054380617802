// RCA theming system from theme-ui
import fonts from "./fonts"
import colors from "./colors"
import sizes from "./sizes"
import styles from "./styles"
import animation from "./animation"
import layout from "./layout"
import type from "./type"

export default {
  ...colors,
  ...fonts,
  ...sizes,
  ...styles,
  ...animation,
  ...layout,
  ...type,
}
