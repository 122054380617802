/** @jsx jsx */
import React from "react" // eslint-disable-line no-unused-vars
import { Link } from "gatsby"
import { jsx, Grid, Box } from "theme-ui"
import SEO from "../components/navigation/seo"

function Gateway() {
  return (
    <>
      <SEO
        title="gateway"
        description={
          "From one world to another, from us to you, and from hyperlink to hyperlink, a single link becomes the gateway that opens a door to new perspectives, possibilities and new work from our BA Graphic and Media Design graduates."
        }
      />
      <Grid
        columns={[1, 1]}
        sx={{
          fontSize: "36px",
        }}
      >
        <Box>
          <p>
            ‘In computing, a hyperlink, or simply a link, is a reference to data
            that the user can follow by clicking or tapping.[1] A hyperlink
            points to a whole document or to a specific element within a
            document. Hypertext is text with hyperlinks. The text that is linked
            from is called anchor text. A software system that is used for
            viewing and creating hypertext is a hypertext system, and to create
            a hyperlink is to hyperlink (or simply to link). A user following
            hyperlinks is said to navigate or browse the hypertext.’
          </p>
          <p sx={{ textAlign: "right" }}>Wikipedia</p>
        </Box>
      </Grid>
    </>
  )
}

export default Gateway
