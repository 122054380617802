/** @jsx jsx */
import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { Link } from "gatsby"
import { jsx, Grid, Box } from "theme-ui"
import SEO from "../components/navigation/seo"

export const query = graphql`
  query categoryQuery($slug: String!) {
    students: allWpPost(
      filter: {
        categories: { nodes: { elemMatch: { slug: { eq: $slug } } } }
        slug: { ne: "peter-cannings-award" }
      }
      sort: { order: ASC, fields: portfolio___displayName }
    ) {
      edges {
        node {
          title
          slug
          id
          uri
          portfolio {
            displayName
            featuredImage {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 700) {
                    # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const Category = ({ data }) => {
  const { students } = data
  return (
    <>
      <SEO
        title="presenters tag"
        description={
          "From one world to another, from us to you, and from hyperlink to hyperlink, a single link becomes the gateway that opens a door to new perspectives, possibilities and new work from our BA Graphic and Media Design graduates."
        }
      />
      {/* <CategoriesGrid />
      <GridList /> */}
      <Grid columns={[2, 3]} gap={2} sx={{ variant: "type.list" }}>
        {students.edges.map(({ node }, index) => (
          <Box key={index}>
            <Link to={`/${node.slug}`}>{node.title}</Link>
          </Box>
        ))}
      </Grid>
    </>
  )
}

export default Category
