/** @jsx jsx */
import React from "react"
import { Link } from "gatsby"
import { jsx, Flex, Box } from "theme-ui"
import HomeInteraction from "../components/HomeInteraction"
import { useBreakpointIndex } from "@theme-ui/match-media"
import SEO from "../components/navigation/seo"

const Index = () => {
  const index = useBreakpointIndex()
  return (
    <>
      <SEO
        title="gateway/gmd"
        description={
          "From one world to another, from us to you, and from hyperlink to hyperlink, a single link becomes the gateway that opens a door to new perspectives, possibilities and new work from our BA Graphic and Media Design graduates."
        }
      />
      <Box
        sx={{
          position: "relative",
          top: 0,
          left: 0,
          width: "100%",
          // height: "100vh",
          zIndex: 10,
        }}
      >
        <Flex
          sx={{
            justifyContent: ["flex-start", "flex-start", "center"],
            alignItems: ["center"],
            height: "80vh",
            color: "primary",
            a: {
              color: "primary",
              textDecoration: "none",
              ":visited": {
                textDecoration: "none",
                color: "primary",
              },
              ":hover": {
                textDecoration: "none",
                color: "secondary",
              },
            },
            // position: "absolute",
            // top: 0,
            // left: 0,
          }}
        >
          <Box>
            <h1
              sx={{
                fontSize: ["60px", "100px", "130px"],
                fontWeight: 300,
              }}
            >
              <Link to={"/presenters"}>
                {index <= 1 ? (
                  <>
                    <Box>
                      <span sx={{ textDecoration: "underline" }}>gatewa</span>y/
                    </Box>
                    <Box>
                      <span sx={{ textDecoration: "underline" }}>gmd</span>
                    </Box>
                  </>
                ) : (
                  <>
                    <span sx={{ textDecoration: "underline" }}>gatewa</span>y/
                    <span sx={{ textDecoration: "underline" }}>gmd</span>
                  </>
                )}
              </Link>
            </h1>
          </Box>
        </Flex>
      </Box>

      <HomeInteraction />
    </>
  )
}

export default Index
