/** @jsx jsx */
import React from "react" // eslint-disable-line no-unused-vars
import { jsx, Container } from "theme-ui"
import Transition from "./navigation/Transition"
import Header from "./navigation/Header"
import Footer from "./navigation/Footer"

const Layout = (props) => {
  return (
    <>
      {props.location.pathname !== "/" && <Header props={props} />}
      {/* <Header /> */}
      <Transition {...props}>
        <main key={`layout-${props.location.pathname}`}>
          <Container p={[3, 4]}>{props.children}</Container>
        </main>
      </Transition>
      {props.location?.pathname !== "/" && <Footer />}
    </>
  )
}

export default Layout
