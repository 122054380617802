/** @jsx jsx */
import React from "react" // eslint-disable-line no-unused-vars

import { jsx, useThemeUI } from "theme-ui"
import { motion } from "framer-motion"

const Path = (props) => (
  <motion.path
    fill="transparent"
    strokeWidth="1"
    stroke="#0000ee"
    // strokeLinecap="round"
    {...props}
  />
)

export const MenuToggle = ({ toggle }) => (
  <button
    onClick={toggle}
    aria-label="menu"
    sx={{
      outline: "none",
      border: "none",
      cursor: "pointer",
      padding: 0,
      mt: 1,
      width: 48,
      height: 46,
      borderRadius: "50%",
      background: "transparent",
    }}
  >
    <svg width="35" height="35" viewBox="0 0 24 24">
      <Path
        variants={{
          closed: { d: "M 2 2.5 L 20 2.5" },
          open: { d: "M 3 16.5 L 17 2.5" },
        }}
      />
      <Path
        d="M 2 9.423 L 20 9.423"
        variants={{
          closed: { opacity: 1 },
          open: { opacity: 0 },
        }}
        transition={{ duration: 0.1 }}
      />
      <Path
        variants={{
          closed: { d: "M 2 16.346 L 20 16.346" },
          open: { d: "M 3 2.5 L 17 16.346" },
        }}
      />
    </svg>
  </button>
)
