//colors object
export default {
  colors: {
    text: "#000",
    background: "#fff",
    primary: "#0000ee",
    secondary: "#ee0000",
    flip: "#fff",
    accent: "#1121d7",
    visited: "#541A8B",
    // accent: "#1121D8",
    modes: {
      dark: {
        text: "#fff",
        background: "#000",
        primary: "#fff",
        secondary: "#fff",
        muted: "#efefef",
        accent: "#fff",
      },
    },
  },
}
