/** @jsx jsx */
import React from "react" // eslint-disable-line no-unused-vars
import { Link } from "gatsby"
import { jsx, Grid, Flex, Box } from "theme-ui"
import SEO from "../components/navigation/seo"
import SidePointer from "../images/svgs/sidepointer.svg"
import Clap from "../images/svgs/clap.svg"
// import Clap from "../images/redsvgs/clap.svg"

import Bubble from "../images/svgs/bubble.svg"
import Camera from "../images/svgs/camera.svg"
import Hand from "../images/svgs/hand.svg"
import Mic from "../images/svgs/mic.svg"
import People from "../images/svgs/people.svg"
import Thumb from "../images/svgs/thumb.svg"
import Plane from "../images/svgs/plane.svg"

import ReactPlayer from "react-player/lazy"

function About() {
  return (
    <>
      <SEO
        title="share"
        description={
          "Historically, pandemics have forced humans to break with the past and imagine their world anew. This one is no different. It is a portal, a gateway between one world and the next."
        }
      />
      <Flex
        sx={{
          justifyContent: "flex-start",
          alignItems: "top",
          mt: [6, 7],
          mb: [5, 5],
        }}
      >
        <Box
          sx={{
            flexGrow: 0,
            flexShrink: 0,
            flexBasis: ["40px", "40px", "80px"],
            maxHeight: ["40px", "40px", "80px"],
            mr: [3, 6],
          }}
        >
          <img src={SidePointer} alt="pointer" />
        </Box>
        <Box
          sx={{
            variant: "type.quote",
            a: {
              color: "primary",
              textDecoration: "underline",
              ":visited": {
                textDecoration: "none",
                color: "primary",
              },
              ":hover": {
                textDecoration: "none",
                color: "secondary",
              },
            },
          }}
        >
          <p>
            ‘Historically, pandemics have forced humans to break with the past
            and imagine their world anew. This one is no different. It is a
            portal, a gateway between one world and the next. We can choose to
            walk through it, dragging the carcasses of our prejudice and hatred,
            our avarice, our data banks and dead ideas, our dead rivers and
            smoky skies behind us. Or we can walk through lightly, with little
            luggage, ready to imagine another world. And ready to fight for it.’
          </p>
          <p sx={{ textAlign: "right" }}>
            <a
              href="https://www.yesmagazine.org/video/coronavirus-pandemic-arundhati-roy/"
              target="_blank"
            >
              Arundhati Roy
            </a>
          </p>
          {/* <p>
            <br />
          </p> */}
          <p>
            This year’s show marks a gateway—from one world to another, from now
            to then, and from us to you. So here we are at the gateway: the
            single hyperlink that opens a door to new perspectives and
            possibilities—spaces, practices, world views. Now our lives are
            online and the screen is the portal into other lives, times and
            places. Raise your hand to speak, un-mute your audio, share your
            screen: design is exactly the field that looks to the future (and
            futures), to ‘imagine otherwise.’ Our GMD graduates have shown that
            they can face any challenge and proudly share their work with you.
          </p>
        </Box>
        {/* <Box
          sx={{
            flexGrow: 0,
            flexShrink: 0,
            flexBasis: ["40px", "40px", "80px"],
              maxHeight: ["40px", "40px", "80px"],
            ml: [2, 9],
            transform: "scaleX(-1)",
          }}
        >
          <img src={Clap} alt="clap" />
        </Box> */}
      </Flex>
      <Grid columns={["1fr 1fr", "1fr 1fr 1fr", "1fr 1fr 1fr 1fr"]}>
        <Flex
          sx={{
            justifyContent: "flex-start",
            alignItems: "top",
            mt: [6, 7],
            mb: [5, 5],
          }}
        >
          <Box
            sx={{
              flexGrow: 0,
              flexShrink: 0,
              flexBasis: ["40px", "40px", "80px"],
              maxHeight: ["40px", "40px", "80px"],
              maxHeight: ["40px", "80px"],
              mr: [3, 6],
            }}
          >
            <img
              src={Clap}
              alt="pointer"
              sx={{ height: "100%", width: "100%" }}
            />
          </Box>
          <Box
            sx={{
              // variant: "type.quote",
              a: {
                color: "primary",
                textDecoration: "underline",
                ":visited": {
                  textDecoration: "none",
                  color: "primary",
                },
                ":hover": {
                  textDecoration: "none",
                  color: "secondary",
                },
              },
            }}
          >
            <h3 sx={{ pb: [5, 6] }}>Identity</h3>
            <Box>
              <Link to="/emma-shore">Emma Shore</Link>
            </Box>
            <Box>
              <Link to="/joanna-marczak">Joanna Marczak</Link>
            </Box>
            <Box>Helga Schmid</Box>
            <Box>Tim Hutchinson</Box>
          </Box>
        </Flex>
        <Flex
          sx={{
            justifyContent: "flex-start",
            alignItems: "top",
            mt: [6, 7],
            mb: [5, 5],
          }}
        >
          <Box
            sx={{
              flexGrow: 0,
              flexShrink: 0,
              flexBasis: ["40px", "40px", "80px"],
              maxHeight: ["40px", "40px", "80px"],
              maxHeight: ["40px", "80px"],
              mr: [3, 6],
            }}
          >
            <img
              src={Thumb}
              alt="pointer"
              sx={{ height: "100%", width: "100%" }}
            />
          </Box>
          <Box
            sx={{
              // variant: "type.quote",
              a: {
                color: "primary",
                textDecoration: "underline",
                ":visited": {
                  textDecoration: "none",
                  color: "primary",
                },
                ":hover": {
                  textDecoration: "none",
                  color: "secondary",
                },
              },
            }}
          >
            <h3 sx={{ pb: [5, 6] }}>Website</h3>

            <Box>
              <Link to="/india-wilson">India Wilson</Link>
            </Box>
            <Box>
              <Link to="/rita-desport">Rita Desport</Link>
            </Box>
            <Box>
              <Link to="/sue-heeyeon-an">Sue Heeyeon An</Link>
            </Box>
            <Box>Alistair McClymont</Box>
          </Box>
        </Flex>
        <Flex
          sx={{
            justifyContent: "flex-start",
            alignItems: "top",
            mt: [6, 7],
            mb: [5, 5],
          }}
        >
          <Box
            sx={{
              flexGrow: 0,
              flexShrink: 0,
              flexBasis: ["40px", "40px", "80px"],
              maxHeight: ["40px", "40px", "80px"],
              maxHeight: ["40px", "80px"],
              mr: [3, 6],
            }}
          >
            <img
              src={Camera}
              alt="pointer"
              sx={{ height: "100%", width: "100%" }}
            />
          </Box>
          <Box
            sx={{
              // variant: "type.quote",
              a: {
                color: "primary",
                textDecoration: "underline",
                ":visited": {
                  textDecoration: "none",
                  color: "primary",
                },
                ":hover": {
                  textDecoration: "none",
                  color: "secondary",
                },
              },
            }}
          >
            <h3 sx={{ pb: [5, 6] }}>Motion Graphics</h3>
            <Box>
              <Link to="/ester-mejibovski">Ester Mejibovski</Link>
            </Box>
            <Box>
              <Link to="/monika-adamczyk">Monika Adamczyk</Link>
            </Box>
            <Box>
              <Link to="/veronica-jones">Veronica Jones</Link>
            </Box>
            <Box>Owen Wells</Box>
            <Box>Ronnie Deelen</Box>
          </Box>
        </Flex>

        <Flex
          sx={{
            justifyContent: "flex-start",
            alignItems: "top",
            mt: [6, 7],
            mb: [5, 5],
          }}
        >
          <Box
            sx={{
              flexGrow: 0,
              flexShrink: 0,
              flexBasis: ["40px", "40px", "80px"],
              maxHeight: ["40px", "40px", "80px"],
              mr: [3, 6],
            }}
          >
            <img
              src={Bubble}
              alt="pointer"
              sx={{ height: "100%", width: "100%" }}
            />
          </Box>
          <Box
            sx={{
              // variant: "type.quote",
              a: {
                color: "primary",
                textDecoration: "underline",
                ":visited": {
                  textDecoration: "none",
                  color: "primary",
                },
                ":hover": {
                  textDecoration: "none",
                  color: "secondary",
                },
              },
            }}
          >
            <h3 sx={{ pb: [5, 6] }}>Social Media</h3>
            <Box>
              <Link to="/marta-urbez">Marta Urbez</Link>
            </Box>
            <Box>Siân Cook</Box>
          </Box>
        </Flex>
        <Flex
          sx={{
            justifyContent: "flex-start",
            alignItems: "top",
            mt: [6, 7],
            mb: [5, 5],
          }}
        >
          <Box
            sx={{
              flexGrow: 0,
              flexShrink: 0,
              flexBasis: ["40px", "40px", "80px"],
              maxHeight: ["40px", "40px", "80px"],
              mr: [3, 6],
            }}
          >
            <img
              src={People}
              alt="pointer"
              sx={{ height: "100%", width: "100%" }}
            />
          </Box>
          <Box
            sx={{
              // variant: "type.quote",
              a: {
                color: "primary",
                textDecoration: "underline",
                ":visited": {
                  textDecoration: "none",
                  color: "primary",
                },
                ":hover": {
                  textDecoration: "none",
                  color: "secondary",
                },
              },
            }}
          >
            <h3 sx={{ pb: [5, 6] }}>Curation</h3>
            <Box>
              <Link to="/abi-chapman">Abigail Chapman</Link>
            </Box>
            <Box>
              <Link to="/lucja-zofia-wroblewska">Lucja Wroblewska</Link>
            </Box>
            <Box>
              <Link to="/mohammed-samad">Mohammed Samad</Link>
            </Box>
            <Box>Deborah Tchoudjinoff</Box>
            <Box>Maria Da Grandra</Box>
            <Box>Michaela French</Box>
            <Box>Monica Biagioli</Box>
          </Box>
        </Flex>
        <Flex
          sx={{
            justifyContent: "flex-start",
            alignItems: "top",
            mt: [6, 7],
            mb: [5, 5],
          }}
        >
          <Box
            sx={{
              flexGrow: 0,
              flexShrink: 0,
              flexBasis: ["40px", "40px", "80px"],
              maxHeight: ["40px", "40px", "80px"],
              mr: [3, 6],
            }}
          >
            <img
              src={Mic}
              alt="pointer"
              sx={{ height: "100%", width: "100%" }}
            />
          </Box>
          <Box
            sx={{
              // variant: "type.quote",
              a: {
                color: "primary",
                textDecoration: "underline",
                ":visited": {
                  textDecoration: "none",
                  color: "primary",
                },
                ":hover": {
                  textDecoration: "none",
                  color: "secondary",
                },
              },
            }}
          >
            <h3 sx={{ pb: [5, 6] }}>Events</h3>
            <Box>Christopher Lutterodt-Quarcoo</Box>
          </Box>
        </Flex>

        <Flex
          sx={{
            justifyContent: "flex-start",
            alignItems: "top",
            mt: [6, 7],
            mb: [5, 5],
          }}
        >
          <Box
            sx={{
              flexGrow: 0,
              flexShrink: 0,
              flexBasis: ["40px", "40px", "80px"],
              maxHeight: ["40px", "40px", "80px"],
              mr: [3, 6],
            }}
          >
            <img
              src={Hand}
              alt="pointer"
              sx={{ height: "100%", width: "100%" }}
            />
          </Box>
          <Box
            sx={{
              // variant: "type.quote",
              a: {
                color: "primary",
                textDecoration: "underline",
                ":visited": {
                  textDecoration: "none",
                  color: "primary",
                },
                ":hover": {
                  textDecoration: "none",
                  color: "secondary",
                },
              },
            }}
          >
            <h3 sx={{ pb: [5, 6] }}>BLM Action</h3>
            <Box>
              <Link to="/alex-dawson-banson">Alexandra Dawson-Banson</Link>
            </Box>
            <Box>
              <Link to="/hannah-balogun">Hannah Balogun</Link>
            </Box>
            <Box>
              <Link to="/">Rhoda Nti</Link>
            </Box>
            <Box>
              <Link to="/veronica-jones">Veronica Jones</Link>
            </Box>
            <Box>Craig Burston</Box>
          </Box>
        </Flex>
        <Flex
          sx={{
            justifyContent: "flex-start",
            alignItems: "top",
            mt: [6, 7],
            mb: [5, 5],
          }}
        >
          <Box
            sx={{
              flexGrow: 0,
              flexShrink: 0,
              flexBasis: ["40px", "40px", "80px"],
              maxHeight: ["40px", "40px", "80px"],
              mr: [3, 6],
            }}
          >
            <img
              src={Plane}
              alt="pointer"
              sx={{ height: "100%", width: "100%" }}
            />
          </Box>
          <Box
            sx={{
              // variant: "type.quote",
              a: {
                color: "primary",
                textDecoration: "underline",
                ":visited": {
                  textDecoration: "none",
                  color: "primary",
                },
                ":hover": {
                  textDecoration: "none",
                  color: "secondary",
                },
              },
            }}
          >
            <h3 sx={{ pb: [5, 6] }}>Publication</h3>
            <Box>
              <Link to="/emma-shore">Emma Shore</Link>
            </Box>
            <Box>
              <Link to="/joanna-marczak">Joanna Marczak</Link>
            </Box>
            <Box>Tim Hutchinson</Box>
            <Box>Alex Cooper</Box>
          </Box>
        </Flex>
      </Grid>
    </>
  )
}

export default About
