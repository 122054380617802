/** @jsx jsx */
import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { Link } from "gatsby"
import { jsx, Grid, Box } from "theme-ui"
import SEO from "../components/navigation/seo"
// import ReactPlayer from "react-player/lazy"

export const query = graphql`
  query Students {
    students: allWpPost(
      filter: { slug: { ne: "peter-cannings-award" } }
      sort: { order: ASC, fields: portfolio___displayName }
    ) {
      edges {
        node {
          # title
          slug
          id
          uri
          portfolio {
            displayName
            featuredImage {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 380) {
                    # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const Presenters = ({ data }) => {
  const { students } = data
  const gifs = [
    "clappin.gif",
    "falling.gif",
    "mouse.gif",
    "thumb.gif",
    "fill.gif",
  ]
  /// make a videos array?
  // const gifNumber = Math.ceil(students.edges.length / 15)
  const gifNumber = 5
  const gifArray = []
  for (let i = 0; i < gifNumber; i++) {
    gifArray[i] = {
      node: {
        type: "gif",
        gif: gifs[Math.floor(Math.random() * gifs.length)],
      },
    }
  }
  const bigArray = [...students.edges, ...gifArray]
  return (
    <>
      <SEO
        title="presenters grid"
        description={
          "From one world to another, from us to you, and from hyperlink to hyperlink, a single link becomes the gateway that opens a door to new perspectives, possibilities and new work from our BA Graphic and Media Design graduates."
        }
      />
      <Grid columns={[2, 3, 4]} gap={2}>
        {bigArray
          .sort(() => Math.random() - 0.5)
          .map(({ node }, index) => {
            if (node.type === "gif") {
              return (
                <div
                  key={`student-${index}`}
                  sx={{ display: "block", boxSizing: "border-box" }}
                >
                  <div
                    sx={{
                      position: "relative",
                      overflow: "hidden",
                      minHeight: "100%",
                    }}
                  >
                    <div
                      aria-hidden={true}
                      sx={{
                        width: "100%",
                        pb: "70%",
                      }}
                    ></div>
                    <img
                      src={`/gifs/${node.gif}`}
                      alt="gateway/gmd"
                      sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        objectPosition: "center center",
                        opacity: 1,
                        transition: "none 0s ease 0s",
                        //
                      }}
                    />
                  </div>
                </div>
              )
            } else if (node.slug) {
              return (
                <div
                  key={`student-${index}`}
                  sx={{
                    a: {
                      ":hover": {
                        color: "primary",
                      },
                    },
                  }}
                >
                  <Link
                    to={`/${node.slug}`}
                    aria-label={node.displayName}
                    sx={{
                      ".image": {
                        display: "block",
                      },
                      ".name": {
                        display: "none",
                      },
                      ":hover": {
                        ".image": {
                          display: "none",
                        },
                        ".name": {
                          display: "flex",
                        },
                      },
                    }}
                  >
                    <Box className="image">
                      {node.portfolio.featuredImage?.localFile?.childImageSharp
                        ?.fluid && (
                        <Img
                          fluid={{
                            ...node.portfolio.featuredImage.localFile
                              .childImageSharp.fluid,
                            ...{ aspectRatio: 4 / 2.8 },
                          }}
                          alt={node.portfolio.displayName}
                        />
                      )}
                    </Box>
                    <Box
                      className="name"
                      sx={{
                        position: "relative",
                        height: "0",
                        pb: "70%",
                      }}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          position: "absolute",
                          borderColor: "primary",
                          borderWidth: 1,
                          borderStyle: "solid",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        {node.portfolio.displayName}
                      </Box>
                    </Box>
                  </Link>
                </div>
              )
            } else {
              return false
            }
          })}
      </Grid>
    </>
  )
}

export default Presenters
