/** @jsx jsx */
import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { Link } from "gatsby"
import { jsx, Grid, Flex, Box } from "theme-ui"
import Obfuscate from "react-obfuscate"
import ReactPlayer from "react-player/lazy"
import SidePointer from "../images/svgs/sidepointer.svg"
import Bubble from "../images/svgs/bubble.svg"
import Plane from "../images/svgs/plane.svg"
import SEO from "../components/navigation/seo"

export const query = graphql`
  query PortfolioQuery($id: String!) {
    student: wpPost(id: { eq: $id }) {
      id
      title
      portfolio {
        biography
        displayName
        email
        website
        featuredImage {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 70) {
                # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        projects {
          fieldGroupName
          projectDescription
          projectTitle
          imagesVideo {
            projectImage {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1152, quality: 70) {
                    # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            vimeoLink
            youtubeLink
          }
        }
      }
    }
    students: allWpPost(
      filter: { slug: { ne: "peter-cannings-award" } }
      sort: { order: ASC, fields: portfolio___displayName }
    ) {
      edges {
        node {
          slug
          id
        }
      }
    }
  }
`

const Portfolio = ({ data }) => {
  const student = data.student
  const students = data.students
  const studentIndex = students.edges.findIndex(
    ({ node }, index) => node.id === student.id
  )
  const previous = studentIndex === 0 ? null : students.edges[studentIndex - 1]
  const next =
    studentIndex > students.edges.length
      ? null
      : students.edges[studentIndex + 1]
  return (
    <>
      <SEO
        title={student.portfolio.displayName}
        description={student.portfolio.biography}
        image={
          student.portfolio.featuredImage?.localFile?.childImageSharp?.fluid
            ?.src
        }
      />
      <h1 sx={{ variant: "type.sectionHeader" }}>
        {student.portfolio.displayName}
      </h1>
      <Grid
        columns={["1fr", "1fr", "1fr 1fr"]}
        sx={{
          mt: [4, 4],
          mb: [7, 8],
        }}
      >
        <Box sx={{ pr: [0, 0] }}>
          <Flex sx={{ alignItems: "flex-start" }}>
            <img
              src={Bubble}
              sx={{ width: "70px", pr: 4, pt: 2 }}
              alt="speech bubble"
            />
            <div
              dangerouslySetInnerHTML={{ __html: student.portfolio.biography }}
            />
          </Flex>
        </Box>
        <Box sx={{ pl: [0] }}>
          <Flex
            sx={{
              alignItems: "flex-start",
              justifyContent: ["flex-start"],
              pt: [3, 0],
              pl: [0, 0, 7],
            }}
          >
            <img src={Plane} sx={{ width: "70px", pr: 4, pt: 2 }} alt="plane" />
            <Box>
              {student.portfolio.email && (
                <Box>
                  <Obfuscate email={student.portfolio.email} />
                </Box>
              )}
              {student.portfolio.website && (
                <Box>
                  <a
                    href={student.portfolio.website}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {student.portfolio.website.replace(/(^\w+:|^)\/\//, "")}
                  </a>
                </Box>
              )}
            </Box>
          </Flex>
        </Box>
      </Grid>
      {/* <Box>
        {student.portfolio.featuredImage?.localFile?.childImageSharp?.fluid && (
          <Img
            fluid={{
              ...student.portfolio.featuredImage.localFile.childImageSharp
                .fluid,
              // ...{ aspectRatio: 4 / 2.8 },
            }}
            alt={student.portfolio.displayName}
          />
        )}
      </Box> */}
      <Box>
        {student.portfolio?.projects?.length > 0 &&
          student.portfolio.projects.map((project, index) => {
            return (
              <Box
                key={`project-${index}`}
                sx={{
                  mt: [2, 3],
                  mb: [6, 8],
                }}
              >
                {project.imagesVideo?.map((item, index) => {
                  return (
                    <Box key={`projectitem-${index}`} sx={{ pb: [1, 2] }}>
                      {item.projectImage?.localFile?.childImageSharp?.fluid && (
                        <Img
                          fluid={{
                            ...item.projectImage.localFile.childImageSharp
                              .fluid,
                          }}
                          alt={`${project.projectTitle} - image ${index + 1}`}
                        />
                      )}
                      {item.vimeoLink &&
                        item.vimeoLink != "" &&
                        item.vimeoLink != " " && (
                          <Box
                            sx={{
                              position: "relative",
                              pt:
                                "56.25%" /* Player ratio: 100 / (1280 / 720) */,
                            }}
                          >
                            <ReactPlayer
                              url={item.vimeoLink}
                              config={{
                                vimeo: {
                                  playerOptions: {
                                    byline: true,
                                    color: "#000099",
                                  },
                                },
                              }}
                              controls={true}
                              playing={false}
                              width="100%"
                              height="100%"
                              sx={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                              }}
                            />
                          </Box>
                        )}
                      {item.youtubeLink &&
                        item.youtubeLink != "" &&
                        item.youtubeLink != " " && (
                          <Box
                            sx={{
                              position: "relative",
                              pt:
                                "56.25%" /* Player ratio: 100 / (1280 / 720) */,
                            }}
                          >
                            <ReactPlayer
                              url={item.youtubeLink}
                              config={{
                                youtube: {
                                  playerVars: { showinfo: 1, controls: true },
                                  embedOptions: {},
                                },
                              }}
                              playing={false}
                              width="100%"
                              height="100%"
                              sx={{
                                position: "absolute",
                                top: 0,
                                left: 0,
                              }}
                            />
                          </Box>
                        )}
                    </Box>
                  )
                })}
                <h2 sx={{ fontSize: [6, 7], mb: [0], mt: [4, 5] }}>
                  {project.projectTitle}
                </h2>
                <Grid columns={["1fr", "1fr", "1fr 1fr"]}>
                  <Flex
                    sx={{
                      mt: [4, 4],
                      mb: [6, 7],
                      alignItems: "flex-start",
                      justifyContent: "flex-end",
                    }}
                  >
                    <div sx={{ width: "70px", pr: 4, pt: 2 }}></div>

                    <div
                      dangerouslySetInnerHTML={{
                        __html: project.projectDescription,
                      }}
                    />
                  </Flex>
                </Grid>
              </Box>
            )
          })}
      </Box>
      <Flex sx={{ justifyContent: "space-between", alignItems: "center" }}>
        {previous && (
          <Link
            to={`/${previous.node.slug}`}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <img
              src={SidePointer}
              sx={{ transform: "scaleX(-1)", width: "80px", p: 2 }}
              alt="previous presenter"
            />
          </Link>
        )}
        {next && (
          <Link
            to={`/${next.node.slug}`}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <img
              src={SidePointer}
              sx={{ width: "80px", p: 2 }}
              alt="next presenter"
            />
          </Link>
        )}
      </Flex>
    </>
  )
}

export default Portfolio
