// fonts object
export default {
  fonts: {
    body: "freight-display-pro, serif",
    heading: "freight-display-pro, serif",
    monospace: "Menlo, monospace",
  },

  //numbers: [ 0, 1,  2,  3,  4,  5,  6,  7,  8,  9,]
  fontSizes: [12, 14, 16, 18, 22, 24, 30, 40, 48, 56, 64, 96],
  fontWeights: {
    body: 300,
    heading: 300,
    bold: 700,
  },
  lineHeights: {
    body: 1.25,
    heading: 1.125,
    headingStories: 1.056,
    cardHeadline: 1.143,
  },
  text: {
    heading: {
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
    },
    primary: {
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
    },
  },
}
